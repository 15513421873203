<template>
  <div style="position: relative">
    <div class="edit_goods_back">
      <div @click="handleEditList" style="width: 45%">
        <i class="el-icon-arrow-left"></i
        ><span class="edit_goods_back_span">商品管理列表</span>
      </div>
      <div
        class="edit_goods_back_span2"
        style="width: 10%"
        v-if="this.$route.query.s == 0"
      >
        商品规格
      </div>
      <div
        class="edit_goods_back_span2"
        style="width: 10%"
        v-if="this.$route.query.s == 1"
      >
        修改库存
      </div>
      <div
        class="edit_goods_back_span2"
        style="width: 10%"
        v-if="this.$route.query.s == 2"
      >
        修改价格
      </div>
      <div
        class="edit_goods_back_span2"
        style="width: 10%"
        v-if="this.$route.query.s == 3"
      >
        修改状态
      </div>
      <div style="width: 50%"></div>
    </div>

    <div class="hr_line"></div>

    <div class="edit_goods_content" v-if="this.$route.query.s == 0">
      <div style="padding: 20px 60px">
        <div class="goods-spec">
          <span>
            商品规格
            <span v-if="this.privateGoodsItem.length == 0">(0/2)</span>
            <span v-if="this.privateGoodsItem.length == 1">(1/2)</span>
            <span v-if="this.privateGoodsItem.length == 2">(2/2)</span>
          </span>
          <el-button
            @click="addPrivateSpec"
            class="goods-spec-add"
            v-if="this.privateGoodsItem.length < 2"
            >添加规格</el-button
          >
        </div>

        <div
          class="goods-container"
          v-for="(attr, index) in privateGoodsItem"
          :key="index"
        >
          <div class="goods-content">
            <div class="goods-content-box">
              <div class="goods-content-left">
                <el-form label-width="80px">
                  <el-form-item label="规格名">
                    <el-select
                      v-model="attr.privateSpecName.specKey"
                      placeholder="请选择（可输入关键词搜索）"
                      filterable
                      style="width: 250px"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="规格值"
                    v-if="attr.privateSpecName.specKey"
                  >
                    <el-tag
                      v-for="tag in attr.dynamicTags"
                      :key="tag['specValue']"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag['specValue'], attr)"
                    >
                      <el-tooltip placement="top">
                        <div slot="content">
                          中文：{{ tag["specValue"] }}<br />
                          繁体：{{ tag["specValueTw"] }}<br />
                          英文：{{ tag["specValueUs"] }}<br />
                          韩文：{{ tag["specValueKr"] }}<br />
                          日文：{{ tag["specValueJp"] }}<br />
                          <!-- <br /> -->
                          <!-- <el-button size="small" @click="handleEditTag(tag)"
                            >编辑</el-button
                          > -->
                        </div>
                        <el-tag style="border: none; height: 30px">{{
                          tag["specValue"]
                        }}</el-tag>
                      </el-tooltip>
                      <!-- <el-dialog
                        title="编辑多语言规格名"
                        :visible.sync="dialogFormVisibleEdit"
                      >
                        <el-form :model="form">
                          <el-form-item
                            label="中文："
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValue"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="繁体："
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValueTw"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="英文："
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValueUs"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="韩文："
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValueKr"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="日文："
                            :label-width="formLabelWidth"
                          >
                            <el-input
                              v-model="form.specValueJp"
                              autocomplete="off"
                            ></el-input>
                          </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                          <el-button @click="dialogFormVisibleEdit = false"
                            >取 消</el-button
                          >
                          <el-button
                            type="primary"
                            @click="handleEditTagSetFormData(attr)"
                            >确 定</el-button
                          >
                        </div>
                      </el-dialog> -->
                    </el-tag>

                    <!-- <el-input
                      :class="`input-new-tag`"
                      v-show="attr.inputVisible"
                      v-model="attr.inputValue"
                      :ref="`saveTagInput${index}`"
                      size="small"
                      @keyup.enter.native="
                        handleInputConfirm(attr.inputValue, attr)
                      "
                      @blur="handleInputConfirm(attr.inputValue, attr)"
                    >
                    </el-input> -->

                    <el-button
                      class="button-new-tag"
                      size="small"
                      v-if="attr.dynamicTags.length < 10"
                      @click="showInput(attr, index)"
                      >+ 添加</el-button
                    >

                    <el-dialog
                      title="添加多语言规格名"
                      :visible.sync="dialogFormVisible"
                    >
                      <el-form :model="form" ref="form" :rules="rules">
                        <el-form-item
                          label="中文："
                          prop="specValue"
                          :label-width="formLabelWidth"
                        >
                          <el-input
                            v-model="form.specValue"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="繁体："
                          :label-width="formLabelWidth"
                        >
                          <el-input
                            v-model="form.specValueTw"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="英文："
                          prop="specValueUs"
                          :label-width="formLabelWidth"
                        >
                          <el-input
                            v-model="form.specValueUs"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="韩文："
                          :label-width="formLabelWidth"
                        >
                          <el-input
                            v-model="form.specValueKr"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="日文："
                          :label-width="formLabelWidth"
                        >
                          <el-input
                            v-model="form.specValueJp"
                            autocomplete="off"
                          ></el-input>
                        </el-form-item>
                      </el-form>
                      <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormVisible = false"
                          >取 消</el-button
                        >
                        <el-button type="primary" @click="setFormData('form')"
                          >确 定</el-button
                        >
                      </div>
                    </el-dialog>
                  </el-form-item>
                </el-form>
              </div>
              <div class="goods-content-right">
                <el-link type="danger" @click="delPrivateSpec(index)"
                  >删除规格</el-link
                >
              </div>
            </div>
          </div>
        </div>

        <p style="margin: 24px 0 10px 0">价格 / 库存</p>
        <!-- row-key="skuId" -->
        <el-table
          :data="tableColumnList.tableBodyList"
          stripe
          tooltip-effect="dark"
          style="width: 100%; margin-top: 1%"
          align="center"
        >
          <el-table-column type="index" label="序号" width="80" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
            </template>
          </el-table-column>

          <el-table-column label="图片" width="240">
            <template slot-scope="scope">
              <el-upload
                ref="upload"
                :action="UploadUrl()"
                :limit="1"
                :auto-upload="false"
                :on-change="
                  (file, fileList) => imageChange(file, fileList, scope.$index)
                "
                list-type="picture-card"
                :on-exceed="handleExceed"
                :on-remove="
                  (file, fileList) => handleRemove(file, fileList, scope.$index)
                "
                :file-list="scope.row.iconUrl"
                :class="{ disabled: uploadDisabled }"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column
            :label="item.propName"
            :property="item.prop"
            v-for="item in tableColumnList.tableHeaderList"
            :key="item.prop"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ scope.row[scope.column.property] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="价格（元）" align="center">
            <template slot-scope="scope">
              <el-input-number
                v-model.number="scope.row.price"
                :precision="2"
                :step="0.1"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="库存" align="center">
            <template slot-scope="scope">
              <el-input-number
                v-model.number="scope.row.stock"
                :min="0"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-text="在售中"
                inactive-text="已下架"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>

        <div class="submit_btn">
          <el-button @click="handleSubmit" type="primary"
            >提交商品信息</el-button
          >
        </div>
      </div>
    </div>

    <div class="edit_goods_content2" v-if="this.$route.query.s == 1">
      <div
        class="edit_goods_content2_con"
        v-for="(item, i) in skuList"
        :key="item.skuId"
      >
        <p>商品规格（中文）：{{ item.skuAttr }}</p>
        <p>商品规格（繁体）：{{ item.skuAttrTw }}</p>
        <p>商品规格（英文）：{{ item.skuAttrUs }}</p>
        <p>商品规格（韩文）：{{ item.skuAttrKr }}</p>
        <p>商品规格（日文）：{{ item.skuAttrJp }}</p>
        <p>当前库存：{{ item.skuStock }}</p>
        <p>
          库存改为：<el-input-number
            v-model.number="item.modelVal"
            :precision="0"
            @change="changeVal"
          ></el-input-number>
        </p>
        <el-button type="primary" @click="handleStockEdit('stock', i)"
          >确认修改</el-button
        >
      </div>
    </div>

    <div class="edit_goods_content2" v-if="this.$route.query.s == 2">
      <div
        class="edit_goods_content2_con"
        v-for="(item, i) in skuList"
        :key="item.skuId"
      >
        <p>商品规格（中文）：{{ item.skuAttr }}</p>
        <p>商品规格（繁体）：{{ item.skuAttrTw }}</p>
        <p>商品规格（英文）：{{ item.skuAttrUs }}</p>
        <p>商品规格（韩文）：{{ item.skuAttrKr }}</p>
        <p>商品规格（日文）：{{ item.skuAttrJp }}</p>
        <p>当前价格：{{ item.skuPrice }}</p>
        <p>
          价格改为：<el-input-number
            v-model.number="item.priceVal"
            :precision="2"
            :step="0.1"
            @change="changeVal"
          ></el-input-number>
        </p>
        <el-button type="primary" @click="handleStockEdit('price', i)"
          >确认修改</el-button
        >
      </div>
    </div>

    <div class="edit_goods_content2" v-if="this.$route.query.s == 3">
      <div
        class="edit_goods_content2_con"
        v-for="(item, i) in skuList"
        :key="item.skuId"
      >
        <p>商品规格（中文）：{{ item.skuAttr }}</p>
        <p>商品规格（繁体）：{{ item.skuAttrTw }}</p>
        <p>商品规格（英文）：{{ item.skuAttrUs }}</p>
        <p>商品规格（韩文）：{{ item.skuAttrKr }}</p>
        <p>商品规格（日文）：{{ item.skuAttrJp }}</p>
        <p>当前状态：{{ item.skuStatus == 0 ? "已下架" : "在售中" }}</p>
        <p>
          状态改为：<el-switch
            v-model="item.statusVal"
            @change="handleStatusVal($event, 'skuList', item, i)"
            active-text="在售中"
            inactive-text="已下架"
          >
          </el-switch>
        </p>
        <el-button type="primary" @click="handleStockEdit('status', i)"
          >确认修改</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import Sortable from "sortablejs";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { sku } from "@/util/LanguageData";

export default {
  inject: ["reload"],
  data() {
    return {
      options: [
        {
          value: "款式",
          label: "款式",
        },
        {
          value: "型号",
          label: "型号",
        },
        {
          value: "尺寸",
          label: "尺寸",
        },
        {
          value: "尺码",
          label: "尺码",
        },
        {
          value: "颜色",
          label: "颜色",
        },
        {
          value: "材质",
          label: "材质",
        },
        {
          value: "口味",
          label: "口味",
        },
        {
          value: "色号",
          label: "色号",
        },
        {
          value: "地点",
          label: "地点",
        },
        {
          value: "货号",
          label: "货号",
        },
        {
          value: "成分",
          label: "成分",
        },
        {
          value: "重量",
          label: "重量",
        },
        {
          value: "类别",
          label: "类别",
        },
        {
          value: "品类",
          label: "品类",
        },
        {
          value: "网络",
          label: "网络",
        },
      ],

      tableColumnList: {
        tableHeaderList: [],
        tableBodyList: [],
      },

      privateGoodsItem: [
        {
          privateSpecName: {}, //规格名
          dynamicTags: [], //规格值数组
          inputVisible: false,
          inputValue: "",
        },
      ],
      urlTableData: [],
      skuList: [],
      stockNum: "", // 修改库存数量
      priceNum: "", // 修改价格

      fileList: [],
      fileListImg: [],
      uploadDisabled: false,

      showInputAttr: "",

      dialogFormVisible: false,
      dialogFormVisibleEdit: false,
      form: {
        specValue: "",
        specValueTw: "",
        specValueUs: "",
        specValueKr: "",
        specValueJp: "",
      },
      formLabelWidth: "120px",
      rules: {
        specValue: [
          { required: true, message: "请输入中文规格名", trigger: "blur" },
        ],
        specValueUs: [
          { required: true, message: "请输入英文规格名", trigger: "blur" },
        ],
      },

      // 分页数据
      currentPage: 1, // 当前页
      pageSize: 10, //要传过去的数据 每页多少条数据
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.skuAdminDetailUrl,
      method: "POST",
      params: this.$route.query.goodsId,

      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          if (res.data.data.skuList) {
            this.skuList = res.data.data.skuList;

            let imgArr = [];
            this.skuList.map((item, i) => {
              imgArr.push({
                url: item.iconUrl,
              });
              this.fileList = imgArr;

              this.skuList[i].modelVal = "";
              this.skuList[i].priceVal = "";

              if (item.skuStatus == 0) {
                this.skuList[i].statusVal = false;
              } else if (item.skuStatus == 1) {
                this.skuList[i].statusVal = true;
              }
            });
          }

          this.urlTableData = res.data.data.skuList;

          if (res.data.data.specList) {
            let arr = [];
            res.data.data.specList.forEach((item, i) => {
              let obj = {};
              obj.privateSpecName = item.specKey;

              obj.dynamicTags = item.speckValueList;
              obj.inputVisible = false;
              arr.push(obj);
            });
            this.privateGoodsItem = arr;
          }

          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  computed: {
    // 计算规格
    calculateAttribute() {
      // 初始化
      let obj = {};
      this.privateGoodsItem.forEach((item) => {
        console.log(item, "计算中");
        // 判断有没有输入规格名
        if (item.privateSpecName.specKey) {
          //规格名:规格值     //'颜色':'尺寸'
          obj[item.privateSpecName.specKey] = item.dynamicTags;
        }
      });
      return obj;
    },
  },
  watch: {
    // 监听规格数据
    calculateAttribute(newVal) {
      let cloneNewVal = JSON.parse(JSON.stringify(newVal));
      let skuTableArr = Object.keys(newVal);

      let attrName = []; //规格名数组
      let attrValue = []; //规格值数组
      for (let key in cloneNewVal) {
        attrName.push(key);
        attrValue.push(cloneNewVal[key]);
      }

      // 表格内容数据（笛卡尔积算法）
      let finalArr = this.cartesianProductOf(...attrValue);

      if (finalArr != undefined) {
        let tableObj = {
          tableBodyList: [],
          tableHeaderList: [],
        };

        // 表格内容
        tableObj.tableBodyList = finalArr.map((item, i) => {
          let obj = {
            price: 0,
            stock: 0,
            status: 0,
          };
          let str = "";

          if (typeof item === "string") {
            str = item;
          } else {
            str = item.specValue;
          }

          let ftemData =
            this.urlTableData.filter((ftem) => ftem.skuAttr == str)[0] || {};

          let iconArr = [];
          if (ftemData.iconUrl) {
            iconArr.push({
              url: ftemData.iconUrl || "",
            });
          }
          obj.iconUrl = iconArr;
          obj.price = ftemData.skuPrice || "0.00";
          obj.stock = ftemData.skuStock || 0;
          obj.status = ftemData.skuStatus == 0 ? false : true;
          obj.skuId = ftemData.skuId;
          let arr = str.split(",");

          for (let i = 0; i < arr.length; i++) {
            obj[attrName[i]] = arr[i];
          }
          return obj;
        });
        this.tableColumnList.tableBodyList = tableObj.tableBodyList; //表格内容数据
        // 表头
        tableObj.tableHeaderList = skuTableArr.map((item) => {
          return {
            prop: item,
            propName: item,
          };
        });
        this.tableColumnList.tableHeaderList = tableObj.tableHeaderList; // 表头
      }
    },
  },

  methods: {
    UploadUrl() {
      return reqUrl.imageUpload;
    },

    handleEditList() {
      this.$router.push({
        path: "/GoodsShelves",
        query: {
          type: this.$route.query.type,
        },
      });
    },

    // 添加规格
    addPrivateSpec(index) {
      this.privateGoodsItem.push({
        privateSpecName: {
          specKey: "",
        },
        dynamicTags: [],
        inputVisible: false,
        inputValue: "",
      });
    },

    delPrivateSpec(index) {
      this.privateGoodsItem.splice(index, 1);
    },

    handleInputConfirm(val, attr) {
      if (val) {
        attr.dynamicTags.push({ specValue: val });
      }
      attr.inputVisible = false;
      attr.inputValue = "";
    },

    handleClose(tag, item) {
      item.dynamicTags.forEach((ytem, y) => {
        if (ytem["specValue"] == tag) {
          item.dynamicTags.splice(y, 1);
        }
      });
    },

    showInput(attr, index) {
      this.showInputAttr = attr;
      this.dialogFormVisible = true;
      // attr.inputVisible = true;
      // this.$nextTick((_) => {
      //   this.$refs[`saveTagInput${index}`][0].$refs.input.focus();
      // });

      this.form.specValue = "";
      this.form.specValueTw = "";
      this.form.specValueUs = "";
      this.form.specValueKr = "";
      this.form.specValueJp = "";
    },

    // 设置语言
    setFormData(formName) {
      this.$refs[formName][0].validate((valid) => {
        if (valid) {
          // const { name, nameJp, nameKr, nameTw, nameUs } = this.form;
          let langObj = this.form;
          if (langObj) {
            this.showInputAttr.dynamicTags.push(
              JSON.parse(JSON.stringify(langObj))
            );
          }
          this.dialogFormVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 编辑规格值
    // handleEditTag(tag) {
    //   console.log(tag, "tag");
    //   this.dialogFormVisibleEdit = true;
    //   this.form.specValue = tag["specValue"];
    //   this.form.specValueUs = tag["specValueUs"];
    //   this.form.specValueTw = tag["specValueTw"];
    //   this.form.specValueKr = tag["specValueKr"];
    //   this.form.specValueJp = tag["specValueJp"];
    // },

    // handleEditTagSetFormData(data) {
    //   let langObj = this.form;
    //   if (langObj) {
    //     console.log(data, "data");
    //     console.log(this.form, "this.form");
    //     // data.dynamicTags.push(langObj);
    //   }
    //   this.dialogFormVisibleEdit = false;
    // },

    // 笛卡尔积算法
    cartesianProductOf(...args) {
      if (args.length > 0) {
        return args.reduce((total, current) => {
          let ret = [];
          total.forEach((a) => {
            current.forEach((b) => {
              ret.push(a.specValue + "," + b.specValue);
            });
          });
          return ret;
        });
      }
    },

    // 修改库存
    handleStockEdit(type, i) {
      if (type == "stock") {
        let modelVal = this.skuList[i].modelVal;
        let skuId = this.skuList[i].skuId;
        this.$confirm(`确认将库存修改为 ${modelVal} 吗`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            showLoading();
            const opt = {
              url: reqUrl.skuAdminEditStockUrl,
              method: "POST",
              params: JSON.stringify({
                skuId: skuId,
                stock: modelVal,
              }),

              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                } else if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.refresh();

                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else if (type == "price") {
        let priceVal = this.skuList[i].priceVal;
        let skuId = this.skuList[i].skuId;
        this.$confirm(`确认将价格修改为 ${priceVal} 吗`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            showLoading();
            const opt = {
              url: reqUrl.skuAdminEditPriceUrl,
              method: "POST",
              params: JSON.stringify({
                skuId: skuId,
                price: priceVal,
              }),

              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                } else if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.refresh();

                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else if (type == "status") {
        console.log(this.skuList, i);
        let statusVal = this.skuList[i].statusVal;
        let skuId = this.skuList[i].skuId;
        this.$confirm(`您确认修改此状态吗`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            showLoading();
            const opt = {
              url: reqUrl.skuAdminEditStatusUrl,
              method: "POST",
              params: JSON.stringify({
                skuId: skuId,
                status: statusVal ? 1 : 0,
              }),

              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                } else if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.refresh();

                  hideLoading();
                }
                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },

    handleStatusVal(e, key, item, i) {
      this[key][i].statusVal = e;
      this.$forceUpdate();
    },

    changeVal() {
      this.$forceUpdate(); //解决点击计数器失效问题
    },

    //选择图片后做图片格式限制（手动上传图片时，before-upload钩子无效，使用此方法替代）
    imageChange(file, fileList, index) {
      if (fileList.length >= 1) {
        this.uploadDisabled = true;
      } else {
        this.uploadDisabled = false;
      }
      console.log(fileList);
      axios.get(file.url, { responseType: "blob" }).then((res) => {
        const formData = new FormData();
        formData.append("file", res.data);
        formData.append("userId", 1);
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: reqUrl.imageUpload,
          header: { "Content-Type": "multipart/form-data" },
          data: formData,
        }).then((res) => {
          this.tableColumnList.tableBodyList[index].iconUrl = [
            { url: res.data.data.url },
          ];
          // this.fileListImg.push(res.data.data.url);
        });
      });

      const isImage =
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg" ||
        file.raw.type == "image/jpeg";
      const isLt5M = file.size < 1024 * 1024 * 10;
      if (!isImage) {
        this.$message.error("上传只能是png,jpg,jpeg格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }

      if (isImage && isLt5M) {
        this.uploadFile = file.raw || null;
      } else {
        fileList.splice(-1, 1);
      }
    },

    //超出限制个数钩子
    handleExceed(files) {
      this.$message.warning("当前限制选择1个文件，请删除图片后重新选择");
    },

    handleRemove(file, fileList, index) {
      this.tableColumnList.tableBodyList[index].iconUrl = [];
      if (this.tableColumnList.tableBodyList[index].length > 0) {
        this.uploadDisabled = true;
      } else {
        this.uploadDisabled = false;
      }
    },

    handleSubmit() {
      this.$confirm("请问您是否确认提交商品信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arrs = [];
          let keysObj = {};
          this.privateGoodsItem.forEach((item) => {
            let objs = {};
            objs.specKey = item.privateSpecName;
            let obj =
              sku.filter((item) => item.zh === objs.specKey.specKey)[0] || {};
            objs.specKey.specKeyJp = obj.jp;
            objs.specKey.specKeyTw = obj.tw;
            objs.specKey.specKeyKr = obj.kr;
            objs.specKey.specKeyUs = obj.us;

            objs.speckValueList = item.dynamicTags;
            arrs.push(objs);
            keysObj = objs;
          });
          let arrList = [];
          this.tableColumnList.tableBodyList.forEach((item, i) => {
            const { price, status, skuId, stock, iconUrl, ...keyVal } = item;
            let vals = Object.values(keyVal).join(",");
            let obj = {};
            const iconObj = iconUrl[0] || {};
            let itemData =
              ((keysObj.speckValueList || []).filter(
                (jtem, j) => jtem.specValue === vals
              ) || [])[0] || {};

            obj.skuAttr = vals;
            obj.skuAttrTw = itemData.specValueTw;
            obj.skuAttrUs = itemData.specValueUs;
            obj.skuAttrKr = itemData.specValueKr;
            obj.skuAttrJp = itemData.specValueJp;
            obj.skuStatus = status ? 1 : 0;
            obj.skuPrice = price;
            obj.skuStock = stock;
            obj.skuId = skuId || "";
            obj.iconUrl = iconObj.url || "";

            arrList.push(obj);
          });

          showLoading();
          const opt = {
            url: reqUrl.skuAdminResetUrl,
            method: "POST",
            params: {
              goodsId: this.$route.query.goodsId,
              skuList: arrList,
              specList: arrs,
            },

            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "提交成功!",
                });
                hideLoading();
                this.$router.push("/GoodsShelves");
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch((err) => {
          console.log(err, "err");
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
